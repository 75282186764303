export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [0,3];

export const dictionary = {
		"/": [4],
		"/boutique": [5],
		"/hebergement/nuagik": [6],
		"/nous-joindre": [7],
		"/nous-joindre/commande": [8],
		"/nous-joindre/devis": [9],
		"/offre-partenaire/harmonie": [~10,[2]],
		"/offre-partenaire/wolfpack-esports": [~11,[2]],
		"/offres": [12,[3]],
		"/offres/a-la-carte": [~13,[3]],
		"/offres/banque-dheures": [~14,[3]],
		"/offres/forfaits": [~15,[3]],
		"/panier": [16],
		"/panier/commander": [17],
		"/particuliers": [18],
		"/particuliers/antivirus": [19],
		"/particuliers/antivirus/comparatif": [20],
		"/particuliers/cartouches-[slug]": [21],
		"/particuliers/demop": [22],
		"/particuliers/papier": [23],
		"/qui-sommes-nous/nos-valeurs": [24],
		"/qui-sommes-nous/pourquoi-ozenik": [25],
		"/recompenses": [26],
		"/seo/[slug]": [~27],
		"/services": [28],
		"/services/antivirus-cybersecurite": [29],
		"/services/assistance-documents": [30],
		"/services/edr": [31],
		"/services/encre-toner-papier": [32],
		"/services/gestion-vulnerabilites": [33],
		"/services/impression": [34],
		"/services/infogestion-fusion": [35],
		"/services/securite-geree": [36],
		"/services/soutien-informatique": [37],
		"/services/soutien-technique": [38],
		"/solutions/osbl": [39],
		"/sos": [40],
		"/special/rendez-vous/merci": [41],
		"/test": [42]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';